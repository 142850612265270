import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"


const PrivacyPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "privacyEn" }}>
    <Seo title="Privacy policy" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>PRIVACY POLICY</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Privacy policy
          </h1>
        </Col>
        <Col sm={12} className="order-md-0">
          <p>
            Regulation (EU) 2016/679 of the European Parliament and of the
            European Council on the Protection of Individuals with a focus on
            the processing of personal data and on the free movement of such
            data and repealing Directive 95/46 / EC entered into force on 25 May
            2018. In this regard, we would like to inform you that Vita Herb INT
            EOOD with UIC 175150004 and address Sofia 1113, 18 Shipchenski
            Prohod Blvd., 8th floor, office 802, applies all possible ways to
            protect the personal data of their customers. Below you will find
            information on how and why we use your personal data, what personal
            data we use, and what your rights are, referring to Regulation (EU)
            2016/679.
          </p>
          <p>
            Personal data is all data through which the customer can be
            identified directly or indirectly. Examples of personal data are:
            three names, PIN, phone number, residence address, email address and
            others.
          </p>
          <p>
            Vita Herb INT Ltd. uses your personal data to process and execute
            incoming orders and related activities such as issuing and
            processing invoices and managing customer relationships. By
            providing your personal data, you give us the opportunity to:
          </p>
          <ul>
            <li>To receive and process your order;</li>
            <li>To deliver the product you want to the address you want;</li>
            <li>To inform you about the status of the order;</li>
            <li>To provide you with appropriate marketing messages;</li>
            <li>
              To comply with the statutory accounting and tax requirements.
            </li>
          </ul>
          <p>We use the following types of data, divided into two groups:</p>
          <ul>
            <li>
              Personal data provided by you when ordering a product. This
              information includes three names, telephone number, delivery
              address, email address, bank account number in case you pay for
              our products by bank transfer.
            </li>
            <li>
              Personal data collected through cookies on our website. This
              information includes the country and city you are in, your gender,
              the type of device you are using. This data is summarized together
              with the data of other visitors to our site and you cannot be
              personally identified through them.
            </li>
          </ul>
          <p>Referring to Regulation (EU) 2016/679, you have the right to:</p>
          <ul>
            <li>Correct and / or update your personal data;</li>
            <li>
              You request information about what personal data we process and
              store;
            </li>
            <li>
              Restrict the processing of your personal data by third parties;
            </li>
            <li>
              Refuse to receive marketing messages from Vita Herb INT EOOD;
            </li>
            <li>
              You request the termination of the use and deletion of your
              personal data.
            </li>
          </ul>
          <p>
            Vita Herb INT EOOD stores your personal data within the time limits
            allowed by law. In case you believe that your data has been
            processed incorrectly or unregulatedly, please contact us at
            office@vitaherb.bg. If you believe that your request has not been
            complied with within a reasonable time, you can contact the CPDP.
          </p>
          <p>
            Vita Herb INT EOOD does not use data from third parties. Vita Herb
            INT EOOD provides data to third parties solely on the basis of
            contractual agreements and confidentiality commitments on their
            part. Third parties that Vita Herb INT Ltd. works with are courier
            service providers, accounting service providers, marketing, banking
            and hosting service providers.
          </p>
          <p>
            You can contact us every weekday from 09:00 to 18:00 in the
            following ways: Email: office@vitaherb.bg, phone number: 02 944 60
            06.
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PrivacyPageEn
